
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Jinky';
  src: url('fonts/jinky/JINKY.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

/* * {
  max-width: 100vw;
} */

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #8a61c4 transparent;
  overflow-x: hidden;
}

html  * {
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: 'Satoshi', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jinky {
  font-family: 'Jinky';
  font-weight: 500;
  color: #D87BFF;
  /* font-size: xx-large; */
  text-shadow: 0 0 20px #6c4c99d4;
}

.jinky-juv {
  font-family: 'Jinky';
  font-weight: 500;
  color: #D87BFF;
  font-size: 150px;
  height: 180px;
  /* margin-top: 50px; */
}

.block-size-1 {
  max-block-size: 14px;
}


.navHeight {
  line-height: 2.5rem
}
